@keyframes wave {
    from {
        d: path("M0,256L40,240C80,224,160,192,240,165.3C320,139,400,117,480,138.7C560,160,640,224,720,229.3C800,235,880,181,960,181.3C1040,181,1120,235,1200,218.7C1280,203,1360,117,1400,74.7L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z");
    }

    25% {
        d: path("M0,160L40,138.7C80,117,160,75,240,64C320,53,400,75,480,90.7C560,107,640,117,720,128C800,139,880,149,960,181.3C1040,213,1120,267,1200,261.3C1280,256,1360,192,1400,160L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z")
    }

    50% {
        d: path("M0,160L40,170.7C80,181,160,203,240,213.3C320,224,400,224,480,213.3C560,203,640,181,720,186.7C800,192,880,224,960,250.7C1040,277,1120,299,1200,304C1280,309,1360,299,1400,293.3L1440,288L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z");
    }

    75% {
        d: path("M0,96L40,85.3C80,75,160,53,240,80C320,107,400,181,480,213.3C560,245,640,235,720,234.7C800,235,880,245,960,245.3C1040,245,1120,235,1200,245.3C1280,256,1360,288,1400,304L1440,320L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z")
    }

    to {
        d: path("M0,256L40,240C80,224,160,192,240,165.3C320,139,400,117,480,138.7C560,160,640,224,720,229.3C800,235,880,181,960,181.3C1040,181,1120,235,1200,218.7C1280,203,1360,117,1400,74.7L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z");
    }
}

@keyframes wave2 {
    from {
        d: path("M0,160L30,144C60,128,120,96,180,117.3C240,139,300,213,360,245.3C420,277,480,267,540,256C600,245,660,235,720,213.3C780,192,840,160,900,133.3C960,107,1020,85,1080,96C1140,107,1200,149,1260,154.7C1320,160,1380,128,1410,112L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z")
    }

    25% {
        d: path("M0,288L30,250.7C60,213,120,139,180,133.3C240,128,300,192,360,213.3C420,235,480,213,540,218.7C600,224,660,256,720,256C780,256,840,224,900,186.7C960,149,1020,107,1080,128C1140,149,1200,235,1260,261.3C1320,288,1380,256,1410,240L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z")
    }

    50% {
        d: path("M0,224L30,229.3C60,235,120,245,180,224C240,203,300,149,360,154.7C420,160,480,224,540,218.7C600,213,660,139,720,112C780,85,840,107,900,117.3C960,128,1020,128,1080,106.7C1140,85,1200,43,1260,58.7C1320,75,1380,149,1410,186.7L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z")
    }

    75% {
        d: path("M0,160L30,149.3C60,139,120,117,180,144C240,171,300,245,360,266.7C420,288,480,256,540,240C600,224,660,224,720,224C780,224,840,224,900,197.3C960,171,1020,117,1080,101.3C1140,85,1200,107,1260,128C1320,149,1380,171,1410,181.3L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z")
    }

    to {
        d: path("M0,160L30,144C60,128,120,96,180,117.3C240,139,300,213,360,245.3C420,277,480,267,540,256C600,245,660,235,720,213.3C780,192,840,160,900,133.3C960,107,1020,85,1080,96C1140,107,1200,149,1260,154.7C1320,160,1380,128,1410,112L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z")
    }
}